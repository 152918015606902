@charset "utf-8";


/************************************************

	mypage

*************************************************/

.pageTitle {
	
}

.orderSearch {
	border-bottom: 1px solid #cacaca;
	
	.orderTable {
		.orderDate {
			select {
				padding: 3px 6px;
				margin-right: 5px;
			}
			
			#orderSearchBtn {
				border: 0;
				background: #cacaca;
			}
		}
	}
}

.orderResult {
	
}

.dayWrap {
	.dayBlock {
		.dayTitle {
			padding: 0.5em 1em;
		}
		
		.orderBlock {
			.orderBlockHead {
				.orderStatus {
					&.ok {
						color: #cb4a28;
					}
				}
			}
			
			.orderItem {
				.orderRepeat a {
					background-color: #464646;
					border: none;
				}
			}
			
			.orderList {
				thead th {
					background: #f1f1f1;
					padding: 1em 6px;
				}
				
				tbody {
					td {
						padding: 1em 6px;
					}
				}
				
				.orderCharge,
				.orderTotal {
					th,
					td {
						background: none;
						padding: 1em 6px;
					}
				}
			}
			
			.orderCancel {
				span {
					color: #CC0000;
					border: 1px solid #CC0000;
					font-size: 14px;
					padding: 0.2em 0.5em;
				}
			}
			
			.orderMoreSwitch {
				margin-bottom: 4px;
			}
			
			.orderMore {
				background: #f1f1f1;
				
				.orderMoreWrap {
					background: none;
				}
			}
		}
		
		
		
	}
	
	.footer {
		border-top: 0;
	}
}

.favorite-list {
	.favorite-list-item {
		border-bottom: 1px solid #cacaca;
		
		.favorite-list-img {
			
		}
		
		.favorite-list-content {
			.favorite-list-txt {
				
			}
			
			.favorite-btnWrap {
				.favorite-btn {
					.btnC {
						background-color: #cb4a28;
					}
				}
			}
		}
	}
}



@media screen and (max-width:768px) {
	input, button, textarea, select {
		box-shadow: none!important;
	}
	
	.orderItem .orderRepeat a {
		background: #464646!important;
	}
	
	.orderStatusLabel .ok {
		background: #cb4a28;
	}

}